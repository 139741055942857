import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CreateNewSubscriptionDialog from './CreateNewSubscriptionDialog';
import React, { useState } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';

export default function CreateNewSubscriptionButton() {
  const { t } = useTranslation('payments');
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <CreateNewSubscriptionDialog onClose={() => setDialogOpen(false)} open={dialogOpen} />
      <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)} startIcon={<AddBoxIcon />}>
        {t('create new subscription')}
      </Button>
    </>
  );
}
