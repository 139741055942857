import { AxiosInstance } from 'axios';
import ApiClient from './ApiClient';
import { Page, PageMetadata } from '../types/Page';
import { Filter, prepareFilterParameters } from '../types/Filter';
import { Credit } from '../types/Credit/Credit';
import { CreditEvent } from '../types/Credit/CreditEvent';

export default class CreditService extends ApiClient {
  private static get creditClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }

  public static async getCredits(size: number, page: number, sort: string, filters: Filter): Promise<{ results: Credit[]; page: PageMetadata }> {
    let params: { [k: string]: any } = {
      size: size,
      page: page,
      sort: 'createdDateTimeUTC,' + sort,
    };

    let response = await this.creditClient.get<Page<Credit, 'credits'>>(`${this.apiDomain}/credits`, {
      params: { ...params, ...prepareFilterParameters(filters) },
      signal: this.abortController.signal,
    });

    if (this.countListener) {
      this.countListener(response.data.page);
    }
    return { results: response.data._embedded?.credits || [], page: response.data.page };
  }

  public static async getCredit(creditId: string): Promise<Credit> {
    let params;
    let res = await this.creditClient.request<Credit>({
      method: 'GET',
      url: `${this.apiDomain}/credits/${creditId}`,
      params: params,
    });
    return res.data;
  }

  public static async getCreditEvents(creditId: string): Promise<CreditEvent[]> {
    let res = await this.creditClient.request<Page<CreditEvent, 'credit_events'>>({
      method: 'GET',
      url: `${this.apiDomain}/credits/${creditId}/events`,
    });

    return res.data._embedded?.credit_events || [];
  }

  public static async createCredit(credit: Credit): Promise<Credit> {
    let res = await this.creditClient.request<Credit>({
      method: 'POST',
      url: `${this.apiDomain}/credits`,
      data: credit,
    });
    return res.data;
  }
}
