import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import LoadingButton from '../../../components/LoadingButton';
import React, { useContext, useState } from 'react';
import SubscriptionService from '../../../services/SubscriptionService';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import DebtorsAutoSuggest from '../../../components/DebtorsAutoSuggest';
import BankAccountsSuggest from '../../../components/BankAccountSuggest';
import ProductAutocomplete from '../../../components/ProductAutocomplete';
import { Products } from '../../../types/Product/Product';
import * as Yup from 'yup';

type NewSubscriptionForm = {
  debitorId: string;
  productId: string;
  bankAccountId: string;
  directDebitActive: boolean;
};

export default function CreateNewSubscriptionDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation(['payments', 'validations']);
  const { sendAlert } = useContext(AlertContext);

  const [isCustomerActive, setIsCustomerActive] = useState(false);

  const initialValues: NewSubscriptionForm = {
    debitorId: '',
    productId: '',
    bankAccountId: '',
    directDebitActive: false,
  };

  const schema = Yup.object().shape({
    debitorId: Yup.string().required(t('validations:customer required')),
    productId: Yup.string().required(t('validations:product required')),
    bankAccountId: Yup.string().required(t('validations:bank account required')),
  });

  const createSubscription = (
    values: NewSubscriptionForm,
    {
      setSubmitting,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
    },
  ) => {
    setSubmitting(true);
    SubscriptionService.createSubscription({
      debtorId: values.debitorId,
      ...values,
    })
      .then(() => {
        sendAlert('success', t('subscription created successfully'));
        setSubmitting(false);
        onClose();
      })
      .catch((error) => {
        sendAlert('error', t('subscription creation failed', { reason: error.message }));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={createSubscription}>
      {({ values, isValid, isSubmitting, handleSubmit, setFieldValue, resetForm }) => (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
          <DialogTitle>{t('create new subscription')}</DialogTitle>
          <DialogContent dividers>
            <DialogContent>
              <BankAccountsSuggest
                disabled={false}
                setFieldValue={setFieldValue}
                setIsConnectedBank={() => {}}
                onChange={() => setIsCustomerActive(true)}
              />
              <DebtorsAutoSuggest
                setLocalFieldValue={setFieldValue}
                directDebitActive={values.directDebitActive}
                disabled={isCustomerActive}
                setIsDisabled={setIsCustomerActive}
                allowCreation={false}
              />
              <ProductAutocomplete name="productId" filters={{ billingType: Products.BillingType.RECURRING }} />
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                resetForm();
                onClose();
              }}
            >
              {t('global:close')}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => handleSubmit()}
              disabled={!isValid}
            >
              {t('create new subscription')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
