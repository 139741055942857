import { Button, Grid } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { FormikInputField } from '@payactive/app-common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getBankAccountLabel } from '../utils/LabelHelper';
import BankService from '../services/BankService';
import { useFormikContext } from 'formik';

export default function BankAccountsSuggest(props) {
  const [options, setOptions] = useState([]);
  const [defaultBank, setDefaultBank] = useState();
  const [initialized, setInitialized] = useState(false);

  const { preselectedId, setFieldValue, setFieldValueInvoice, className, setIsConnectedBank, disabled, label } = props;
  const { setFieldTouched } = useFormikContext();
  const history = useHistory();

  const { t } = useTranslation('payments');

  useEffect(() => {
    BankService.getBankAccounts().then((res) => {
      if (res) {
        setOptions(res.bankAccounts);
        setInitialized(true);
        setIsConnectedBank(res.bankAccounts.length > 0);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let bank;
    if (preselectedId !== undefined) {
      bank = options.find((option) => option.id === preselectedId);
    } else {
      bank = options.find((option) => option.default === true);
    }

    setDefaultBank(bank);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (defaultBank) {
      setFieldValueInvoice
        ? setFieldValueInvoice('creditorBankAccount', {
            id: defaultBank.id,
            bankName: defaultBank.bankName,
            accountHolder: defaultBank.accountHolder,
            iban: defaultBank.iban,
            bic: defaultBank.bic,
          })
        : setFieldValue('bankAccountId', defaultBank.id).then(() => setFieldTouched('bankAccountId', true, true));
    } else {
      setFieldValue('bankAccountId', '').then(() => setFieldTouched('bankAccountId', true, true));
    }
    setFieldValue('directDebitActive', defaultBank?.directDebitActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBank]);

  const redirectToBankAccount = () => {
    return history.push(`/dashboard/company/bankaccount`);
  };

  return (
    <>
      {initialized && options.length === 0 ? (
        <Grid item xs={12}>
          <Alert
            severity="warning"
            variant="filled"
            action={
              <Button onClick={redirectToBankAccount} aria-label="close" variant="contained" size="medium">
                {t('connect')}{' '}
              </Button>
            }
          >
            {t('no bank account connected')}
          </Alert>
        </Grid>
      ) : (
        <Autocomplete
          className={className}
          value={defaultBank || null}
          id="bankAccounts-autocomplete"
          options={options}
          getOptionLabel={(option) => getBankAccountLabel(option)}
          renderInput={(params) => (
            <FormikInputField icon="account_balance" name="bankAccountId" label={label ? label : t('select creditor account')} {...params} />
          )}
          onChange={(_event, newValue) => {
            setDefaultBank(newValue);
            setFieldValue('directDebitActive', newValue?.directDebitActive);
          }}
          fullWidth
          disabled={disabled}
        ></Autocomplete>
      )}
    </>
  );
}
