import ApiClient from './ApiClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { Page } from '../types/Page';
import { UserWithRole } from '../types/User/UserWithRole';
import { Invitation, NewInvitation } from '../types/User/Invitation';

export default class TeamMemberService extends ApiClient {
  private static get teamMemberClient(): AxiosInstance {
    return this.client;
  }

  public static async getTeam(): Promise<UserWithRole[]> {
    let res = await this.teamMemberClient.request<Page<UserWithRole, 'userWithRoleList'>>({
      method: 'GET',
      url: this.apiDomain + '/teammembers',
    });
    return res.data._embedded?.userWithRoleList || [];
  }

  public static async getTeamInvitations(): Promise<UserWithRole[]> {
    let res = await this.teamMemberClient.request<Page<UserWithRole, 'userWithRoleList'>>({
      method: 'GET',
      url: this.apiDomain + '/teammembers/invitations',
    });

    return res.data._embedded?.userWithRoleList || [];
  }

  public static async invite(email: string): Promise<boolean> {
    let res = await this.teamMemberClient.request({
      method: 'POST',
      url: this.apiDomain + '/teammembers/invitations',
      data: {
        emailAddress: email,
      },
    });
    return res.status === 201;
  }

  public static async getInvitation(invitationId: string): Promise<Invitation> {
    let res = await this.teamMemberClient.request({
      method: 'GET',
      url: this.apiDomain + '/teammembers/invitations/' + invitationId,
    });

    return res.data;
  }

  public static async removeFromTeam(userRoleId: string) {
    let res = await this.teamMemberClient.request({
      method: 'DELETE',
      url: this.apiDomain + '/teammembers/' + userRoleId,
    });
    return res.status === 200;
  }

  public static async join(invitationId: string, data: NewInvitation): Promise<AxiosResponse> {
    return await this.teamMemberClient.request<NewInvitation, AxiosResponse>({
      method: 'POST',
      url: this.apiDomain + '/teammembers/invitations/' + invitationId + '/join',
      data: data,
    });
  }
}
