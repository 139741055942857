import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ServicePeriods } from '../../../types/Invoice/ServicePeriods';
import { TaxRate } from '../../../types/Invoice/TaxRate';
import DebtorService from '../../../services/DebtorService';

const createSchemas = (t: TFunction) => {
  return {
    0: Yup.object().shape({
      debitorId: Yup.string()
        .required(t('required', { field: t('debitor') }))
        .test('customer-address-validation', t('validations:customer address incomplete'), async (value, testContext) => {
          if (!value) return false;

          let customer = await DebtorService.getDebtor(value);
          if (!customer) return false;

          let address = customer.address;
          if (!address) return false;

          return Boolean((address.line || (address.street && address.houseNumber)) && address.zipCode && address.city && address.country);
        }),
      paymentTermInDays: Yup.number().positive(t('payment term positive')),
      servicePeriodEnd: Yup.string()
        .nullable()
        .when(['servicePeriodVariant', 'servicePeriodStart'], (servicePeriodVariant: any, servicePeriodStart: any) => {
          if (servicePeriodVariant === ServicePeriods.SERVICE_PERIOD) {
            return Yup.string().test('End date bigger than start date', 'End date must be after begin date', (value, testContext) => {
              if (value === undefined) {
                return false;
              }
              return Date.parse(value) > Date.parse(servicePeriodStart);
            });
          } else {
            return Yup.string().nullable();
          }
        }),
      reverseCharge: Yup.mixed().when('vatId', {
        is: (value: string) => {
          return !(value && value.trim().length > 0);
        },
        then: Yup.bool().isFalse(t('debitors:vatId is needed')),
        otherwise: Yup.mixed(),
      }),
      positions: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().max(120).required(t('is required')),
          quantity: Yup.number().required(t('is required')).moreThan(0, t('> 0')),
          price: Yup.number().required(t('is required')),
          taxRate: Yup.number(),
        }),
      ),
    }),
  };
};

const taxOptions: TaxRate[] = [
  {
    description: 'USt 7%',
    rate: 7,
  },
  {
    description: 'USt 19%',
    rate: 19,
  },
];

export { createSchemas, taxOptions };
