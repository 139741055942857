import { Product } from '../types/Product/Product';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import ProductService from '../services/ProductService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormikInputField } from '@payactive/app-common';
import { Filter } from '../types/Filter';
import { AlertContext } from './contexts/WithAlertContext';
import { useFormikContext } from 'formik';

export default function ProductAutocomplete({ name, filters }: { name: string; filters?: Filter }) {
  const { t } = useTranslation('products');
  const alert = useContext(AlertContext);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [timeout, storeTimeout] = useState<NodeJS.Timeout>();

  const updateProducts = (inputValue: string) => {
    ProductService.getProducts(5, 0, 'asc', { search: inputValue, ...filters })
      .then(({ results }) => setProducts(results))
      .catch((error) => alert.sendAlert('error', error.message))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    ProductService.getProducts(5, 0, 'asc', filters)
      .then(({ results }) => {
        setProducts(results);
      })
      .catch((error) => alert.sendAlert('error', error.message));
  }, []);

  return (
    <Autocomplete
      options={products}
      getOptionLabel={(product) => product.name}
      loading={isLoading}
      loadingText={t('global:loading')}
      noOptionsText={t('global:nothing found')}
      onChange={(event, product) => {
        setFieldValue(name, product?.id);
      }}
      onBlur={() => setFieldTouched(name, true, true)}
      onInputChange={(_event, value) => {
        setIsLoading(true);
        if (timeout) {
          clearTimeout(timeout);
          storeTimeout(undefined);
        }
        storeTimeout(setTimeout(() => updateProducts(value), 750));
      }}
      renderInput={(params) => <FormikInputField icon="local_mall" name={name} label={t('select product')} {...params} />}
      openOnFocus
    />
  );
}
