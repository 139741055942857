import React, { FormEvent, MouseEvent, useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, DialogActions } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FormikInputField } from '@payactive/app-common';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import LoadingButton from './LoadingButton';
import { getDebtorLabel } from '../utils/LabelHelper';
import { AlertContext } from './contexts/WithAlertContext';
import DebtorManuallyForm from '../pages/debtors/dialogs/DebtorManuallyForm';
import { createSchemas } from '../pages/debtors/dialogs/schemas';
import { Debtor, Debtors, NewDebtor } from '../types/Debtor/Debtor';
import { InvoiceFormValues } from '../types/Invoice/Invoice';
import { FormikErrors } from 'formik/dist/types';
import { PaymentMethod } from '../types/Invoice/PaymentMethod';
import DebtorService from '../services/DebtorService';
import { NewSubscription } from '../types/Subscription/Subscription';

const SEARCH_TYPE_DELAY_MS = 1000;

const filter = createFilterOptions<Debtor | { inputValue: string; title: string }>();

export default function DebtorsAutoSuggest({
  setLocalFieldValue,
  setIsDisabled,
  className,
  disabled,
  debtor,
  directDebitActive,
  onChange,
  allowCreation = true,
  customerFilter,
}: {
  setLocalFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<InvoiceFormValues | NewSubscription>>;
  setIsDisabled: (newState: boolean) => void;
  className?: string;
  disabled: boolean;
  debtor?: Debtor;
  directDebitActive: boolean;
  onChange?: (debtor: Debtor | undefined) => void;
  allowCreation?: boolean;
  customerFilter?: (customer: Debtor) => boolean;
}) {
  const [query, setQuery] = useState<string>();
  const [options, setOptions] = useState<(Debtor | { inputValue: string; title: string })[]>(
    [] as (Debtor | { inputValue: string; title: string })[],
  );
  const [timer, setTimer] = useState<number>();
  const [newCustomer, setNewCustomer] = useState(false);
  const [currentValue, setCurrentValue] = useState<Debtor | undefined>(debtor);
  const [currentFirstNameValue, setCurrentFirstNameValue] = useState<string>();
  const [loading, setLoading] = useState(false);
  const alerting = useContext(AlertContext);
  const { setFieldTouched } = useFormikContext();

  const { t } = useTranslation(['debitors', 'global', 'validations']);
  const schema = createSchemas(t)[0];

  const initialValues: NewDebtor = {
    type: Debtors.Type.PERSON,
    paymentMethod: PaymentMethod.ONLINE_PAYMENT,
    companyName: undefined,
    emailAddress: '',
    firstName: currentFirstNameValue || '',
    lastName: '',
    address: {
      line: '',
      zipCode: '',
      city: '',
      country: '',
    },
  };

  useEffect(() => {
    DebtorService.getDebtors(20, 0, 'lastName,asc').then(({ results }: { results: Debtor[] }) => {
      setOptions(customerFilter ? results.filter(customerFilter) : results);
    });
  }, [newCustomer]);

  const PaymentMethods = [
    {
      label: t('payment method online payment'),
      value: 'ONLINE_PAYMENT',
    },
    {
      label: t('payment method direct debit'),
      value: 'DIRECT_DEBIT',
    },
  ];

  const filterPaymentMethods = (debtor?: Debtor) => {
    if (directDebitActive && !!debtor && debtor.paymentMethod === PaymentMethod.DIRECT_DEBIT) {
      return PaymentMethods;
    } else {
      return PaymentMethods.filter((paymentMethod) => paymentMethod.value !== PaymentMethod.DIRECT_DEBIT);
    }
  };

  useEffect(() => {
    const filtered = filterPaymentMethods(currentValue);

    setLocalFieldValue('paymentMethods', filtered);
    if (filtered.length > 1) {
      setLocalFieldValue('directDebitActivated', true);
    } else {
      setLocalFieldValue('directDebitActivated', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue, setLocalFieldValue]);

  useEffect(() => {
    if (!query) {
      return;
    }
    if (timer) {
      window.clearTimeout(timer);
    }
    setTimer(
      window.setTimeout(() => {
        setLoading(true);
        DebtorService.search(query)
          .then(({ results }) => {
            setOptions(customerFilter ? results.filter(customerFilter) : results);
          })
          .catch(() => {
            //TODO handle catch
          })
          .finally(() => {
            setLoading(false);
          });
      }, SEARCH_TYPE_DELAY_MS),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (debtor?.emailAddress !== undefined) {
      setCurrentValue(debtor);
      setDebtorData(debtor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const setDebtorData = (debtor?: Debtor) => {
    /*setLocalFieldValueInvoice('debitor', {
      emailAddress: debtor ? debtor.emailAddress : '',
      firstName: debtor ? debtor.firstName : '',
      lastName: debtor ? debtor.lastName : '',
      type: debtor ? Debtors.Type : '',
      companyName: debtor ? debtor.companyName : '',
      address: {
        street: debtor ? debtor.address?.street : '',
        zipCode: debtor ? debtor.address?.zipCode : '',
        houseNumber: debtor ? debtor.address?.houseNumber : '',
        city: debtor ? debtor.address?.city : '',
        country: debtor ? debtor.address?.country : '',
      },
    });*/
    setLocalFieldValue('debitorId', debtor ? debtor.id : '');
    setLocalFieldValue('debitor', debtor ? debtor : '');
    const filtered = filterPaymentMethods(debtor ? debtor : undefined);
    setLocalFieldValue('paymentMethods', filtered);
    if (filtered.length > 1) {
      setLocalFieldValue('directDebitActivated', true);
    } else {
      setLocalFieldValue('directDebitActivated', false);
    }
    onChange && onChange(debtor);
  };

  const _handleSubmit = (
    e: FormEvent<HTMLFormElement> | MouseEvent<any> | undefined,
    values: NewDebtor,
    { setSubmitting, setStatus }: { setSubmitting: (isSubmitting: boolean) => void; setStatus: (status?: any) => void },
  ) => {
    !!e && e.preventDefault();

    DebtorService.createDebtor(values)
      .then((res) => {
        alerting.sendAlert('success', t('new debtor created'));
        setNewCustomer(false);
        setIsDisabled(false);
        setDebtorData(res);
        setCurrentValue(res);
      })
      .catch((err) => {
        setStatus(err.message);
      })
      .finally(() => setSubmitting(false));
  };

  function isDebtor(option: Debtor | { inputValue: string; title: string }): option is Debtor {
    return !(option as { inputValue: string; title: string }).inputValue;
  }

  return (
    <div>
      {!newCustomer && (
        <Autocomplete
          disabled={disabled}
          value={currentValue}
          onInputChange={(event, newInputValue) => {
            setQuery(newInputValue);
          }}
          onChange={(event, newValue) => {
            if (newValue === null) {
              setDebtorData(undefined);
            } else if (isDebtor(newValue)) {
              setDebtorData(newValue);
              setCurrentValue(newValue);
            } else if (allowCreation) {
              setNewCustomer(true);
              setIsDisabled(true);
            }
          }}
          onBlur={() => setFieldTouched('debitorId', true, true)}
          filterOptions={(options, state) => {
            const filtered = filter(options, state);
            if (state.inputValue !== '' && allowCreation) {
              filtered.push({
                inputValue: state.inputValue,
                title: `${t('global:add', { name: state.inputValue })}`,
              });
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={options}
          getOptionLabel={(option) => {
            if (isDebtor(option)) {
              let s = getDebtorLabel(option, true) || option.emailAddress;
              console.log(s);
              return s;
            } else {
              return option.inputValue;
            }
          }}
          renderOption={(option) => {
            if (isDebtor(option)) {
              return getDebtorLabel(option, true) || option.emailAddress;
            } else {
              setCurrentFirstNameValue(option.inputValue);
              return option.title;
            }
          }}
          renderInput={(params) => (
            <FormikInputField
              {...params}
              required
              className={className}
              icon="person"
              name="debitorId"
              label={allowCreation ? t('add customer') : t('select customer')}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="primary" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}

      {newCustomer && (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values: NewDebtor, formikHelpers: FormikHelpers<NewDebtor>) => {
            _handleSubmit(undefined, values, formikHelpers);
          }}
        >
          {({ values, status, isSubmitting, setFieldValue, resetForm, setSubmitting, setStatus }) => (
            <form onSubmit={(e) => _handleSubmit(e, values, { setSubmitting, setStatus })}>
              <DebtorManuallyForm
                status={status}
                values={values}
                setFieldValue={setFieldValue}
                defaultValue={currentFirstNameValue}
                mode={'create'}
              />
              <DialogActions>
                <Button
                  onClick={() => {
                    setNewCustomer(false);
                    setIsDisabled(false);
                    resetForm();
                  }}
                  disabled={isSubmitting}
                  color="primary"
                >
                  {t('Cancel')}
                </Button>
                <LoadingButton
                  loading={isSubmitting}
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={(e) => _handleSubmit(e, values, { setSubmitting, setStatus })}
                >
                  {t('Save customer')}
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}
