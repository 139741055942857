import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BankAccountsSuggest from '../BankAccountSuggest';
import { Formik, FormikHelpers } from 'formik';
import DebtorsAutoSuggest from '../DebtorsAutoSuggest';
import { FormikInputField, FormikSizedInputField } from '@payactive/app-common';
import LoadingButton from '../LoadingButton';
import CreditService from '../../services/CreditService';
import { AlertContext } from '../contexts/WithAlertContext';
import { PaymentMethod } from '../../types/Invoice/PaymentMethod';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  input: {
    padding: theme.spacing(1),
  },
}));

type NewCreditForm = {
  debitorId: string;
  bankAccountId: string;
  amount: number;
  purpose: string;
  directDebitActive: boolean;
};

export default function NewCreditDialog({ isOpen, onClose, onSuccess }: { isOpen: boolean; onClose: () => void; onSuccess?: () => void }) {
  const { t } = useTranslation(['global', 'credits', 'company', 'debitors', 'validations']);
  const alert = useContext(AlertContext);
  const classes = useStyles();

  const [isDebtorAutoSuggestDisabled, setIsDebtorAutoSuggestDisabled] = useState(false);

  const submit = (values: NewCreditForm, { setSubmitting, resetForm }: FormikHelpers<NewCreditForm>) => {
    CreditService.createCredit(Object.assign(values))
      .then(() => {
        alert.sendAlert('success', t('credits:credit created'));
        if (onSuccess) onSuccess();
        onClose();
        resetForm();
      })
      .catch((err) => {
        alert.sendAlert('error', err.message);
      })
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    debitorId: '',
    bankAccountId: '',
    amount: 0,
    purpose: '',
    directDebitActive: false, // set in BankAccountSuggest - I don't know why ... - mgy
  };

  return (
    <Formik
      initialValues={Object.assign(initialValues)}
      onSubmit={submit}
      validationSchema={Yup.object().shape({
        debitorId: Yup.string().required(t('validations:customer required')),
        bankAccountId: Yup.string().required(t('validations:bank account required')),
        amount: Yup.number().moreThan(0, t('validations:has to be positive')).required(t('validations:amount required')),
        purpose: Yup.string().required(t('validations:purpose required')),
      })}
    >
      {({ setFieldValue, values, isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" open={isOpen} onClose={onClose} fullWidth>
          <DialogTitle id="alert-dialog-title">{t('credits:new credit')}</DialogTitle>
          <DialogContent dividers>
            <Typography variant="h6">{t('company:bank account')}</Typography>
            <BankAccountsSuggest
              className={classes.input}
              setFieldValue={setFieldValue}
              setIsConnectedBank={() => {}}
              label={t('credits:select bank account')}
            />

            <Typography variant="h6">{t('debitors:customer')}</Typography>
            <DebtorsAutoSuggest
              className={classes.input}
              setLocalFieldValue={setFieldValue}
              setIsDisabled={setIsDebtorAutoSuggestDisabled}
              disabled={isDebtorAutoSuggestDisabled}
              directDebitActive={values.directDebitActive}
              allowCreation={false}
              customerFilter={(customer) => customer.paymentMethod === PaymentMethod.DIRECT_DEBIT}
            />

            <Typography variant="h6">{t('credits:credit details')}</Typography>
            <FormikInputField icon="euro_symbol" value={values.amount} type="number" name="amount" label={t('credits:amount')} fullWidth />
            <FormikSizedInputField icon="short_text" max={120} name="purpose" label={t('credits:purpose')} fullWidth value={values.purpose} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {t('global:cancel')}
            </Button>
            <LoadingButton loading={isSubmitting} type="submit" onClick={() => handleSubmit()}>
              {t('global:create')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
