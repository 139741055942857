import ApiClient from './ApiClient';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Page, PageMetadata } from '../types/Page';
import { Debtor, NewDebtor } from '../types/Debtor/Debtor';
import { DebtorStats } from '../types/Debtor/DebtorStats';
import { Payments } from '../types/Payment/Payment';
import { DebtorInvitation } from '../types/Debtor/DebtorInvitation';

export default class DebtorService extends ApiClient {
  private static get debtorClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }

  public static async getDebtors(
    size: number,
    page: number,
    sort: string | string[],
    search?: string,
    includeDeleted = false,
  ): Promise<{ results: Debtor[]; page: PageMetadata }> {
    let params = {
      size: size,
      page: page,
      sort: sort,
      includeDeleted: includeDeleted,
    };
    if (super.isFilterSet(search)) {
      params = {
        ...params,
        ...{
          search: search,
        },
      };
    }
    let res = await this.debtorClient.request<Page<Debtor, 'debitors'>>({
      method: 'GET',
      url: this.apiDomain + '/debitors',
      params: params,
      signal: this.abortController.signal,
    });
    return { results: res.data._embedded?.debitors || [], page: res.data.page };
  }

  public static async getDebtor(debtorId: string): Promise<Debtor> {
    return (
      await this.debtorClient.request<Debtor>({
        method: 'GET',
        url: this.apiDomain + '/debitors/' + debtorId,
      })
    ).data;
  }

  public static async getStats(debtorId: string): Promise<DebtorStats> {
    let res = await this.debtorClient.request<DebtorStats>({
      method: 'GET',
      url: this.apiDomain + '/stats/customer/' + debtorId,
    });

    return res.data;
  }

  public static async getAvailablePaymentMethods(debtorId: string): Promise<Payments.PaymentMethod[]> {
    let res = await this.debtorClient.request<Payments.PaymentMethod[]>({
      method: 'GET',
      url: this.apiDomain + '/debitors/' + debtorId + '/payment-methods',
    });

    return res.data;
  }

  public static async createDebtor(data: NewDebtor): Promise<Debtor> {
    try {
      const response = await this.debtorClient.request<NewDebtor, AxiosResponse<Debtor>>({
        method: 'POST',
        url: this.apiDomain + '/debitors',
        data: data,
      });
      return response.data;
    } catch (error) {
      let errorMessage;
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data['message'];
        if (!errorMessage) {
          errorMessage = error.message;
        }
      } else errorMessage = 'Unknown error occurred';
      throw new Error(errorMessage);
    }
  }

  public static async inviteDebtor(data: DebtorInvitation): Promise<boolean> {
    await this.debtorClient.request<DebtorInvitation>({
      method: 'POST',
      url: this.apiDomain + '/debitors/invitations',
      data: data,
    });
    return true;
  }

  /**
   * @deprecated use @see getDebtors with search parameter instead
   * @param search
   */
  public static async search(search: string) {
    let res = await this.debtorClient.request<Page<Debtor, 'debitors'>>({
      method: 'GET',
      url: this.apiDomain + '/debitors/search',
      params: {
        search: search,
      },
    });

    return { results: res.data._embedded?.debitors || [], page: res.data.page };
  }

  public static async deleteDebtor(debtorId: string): Promise<boolean> {
    await this.debtorClient.request({
      method: 'DELETE',
      url: this.apiDomain + '/debitors/' + debtorId,
    });

    return true;
  }

  public static async resendOnboardInvitation(debtorId: string): Promise<string> {
    let params = {
      invitationType: 'EMAIL',
    };
    let res = await this.debtorClient.request({
      method: 'PATCH',
      url: this.apiDomain + '/debitors/' + debtorId + '/resend-invitation',
      params: params,
    });

    return res.data;
  }

  public static async changePaymentMethod(debtorId: string): Promise<string> {
    let params = {
      invitationType: 'EMAIL',
    };
    let res = await this.debtorClient.request<{ invitationType: string }, AxiosResponse<string>>({
      method: 'PATCH',
      url: this.apiDomain + '/debitors/' + debtorId + '/change-payment-method',
      params: params,
    });

    return res.data;
  }

  public static async updateDebtor(debtorId: string, debtor: NewDebtor): Promise<boolean> {
    let res = await this.debtorClient.request<NewDebtor>({
      method: 'PUT',
      url: this.apiDomain + '/debitors/' + debtorId,
      data: debtor,
    });
    return res.status === 200;
  }
}
