import { Button } from '@payactive/app-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NewCreditDialog from './NewCreditDialog';

export default function NewCreditDialogButton({ onSuccess }: { onSuccess?: () => void }) {
  const { t } = useTranslation(['global', 'credits']);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  return (
    <>
      <Button name="newCredit" label={t('credits:new credit')} onClick={() => setIsDialogOpen(true)} />
      <NewCreditDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} onSuccess={onSuccess} />
    </>
  );
}
