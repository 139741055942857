import { useTranslation } from 'react-i18next';
import GenericTableFilters, { StateFilter } from '../../components/filter/GenericTableFilters';
import { Grid } from '@material-ui/core';
import PageHeader from '../../components/ui/blocks/PageHeader';
import React from 'react';
import CreditsTable from './table/CreditsTable';
import { CreditListDataContextProvider, CreditsListDataContext } from './CreditsListDataContext';
import { Credits } from '../../types/Credit/Credit';
import NewCreditDialogButton from '../../components/credits/NewCreditDialogButton';

export default function CreditsView() {
  const { t } = useTranslation(['global', 'credits']);

  const filterArray: StateFilter[] = [
    { name: t('credits:PENDING'), value: Credits.State.PENDING },
    { name: t('credits:CREATING'), value: Credits.State.CREATING },
    { name: t('credits:SENT'), value: Credits.State.SENT },
    { name: t('credits:ERROR'), value: Credits.State.ERROR },
  ];

  return (
    <CreditListDataContextProvider pageSizeOptions={[5, 10, 30]} hasPaginationProvider={true}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid>
          <PageHeader subtitle={t('credits:credits')} title={t('credits:current credits')} />
        </Grid>
        <Grid item style={{ zIndex: 1 }}>
          <CreditsListDataContext.Consumer>{({ reload }) => <NewCreditDialogButton onSuccess={reload} />}</CreditsListDataContext.Consumer>
        </Grid>
      </Grid>
      <Grid>
        <GenericTableFilters states={filterArray} context={CreditsListDataContext} t={t} />
      </Grid>
      <CreditsTable />
    </CreditListDataContextProvider>
  );
}
