import { AxiosInstance, AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import { InstalmentPlan, NewInstalmentPlan } from '../types/InstalmentPlan/InstalmentPlan';
import { Page, PageMetadata } from '../types/Page';
import { Filter, prepareFilterParameters } from '../types/Filter';
import { InstalmentPlanPreview, NewInstalmentPlanPreview } from '../types/InstalmentPlan/InstalmentPlanPreview';

export default class InstalmentPlanService extends ApiClient {
  private static get instalmentPlanClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }

  public static async getInstalmentPlans(
    size: number,
    page: number,
    sort: string,
    filters: Filter,
  ): Promise<{ results: InstalmentPlan[]; page: PageMetadata }> {
    let params = {
      size: size,
      page: page,
      sort: 'createdDateTime,' + sort,
      ...prepareFilterParameters(filters),
    };

    const response = await this.instalmentPlanClient.get<Page<InstalmentPlan, 'instalmentPlans'>>(this.apiDomain + '/instalment-plans', { params });

    if (this.countListener) {
      this.countListener(response.data.page);
    }
    return { results: response.data._embedded?.instalmentPlans || [], page: response.data.page };
  }

  public static async createInstalmentPlanPreview(payload: NewInstalmentPlanPreview): Promise<InstalmentPlanPreview> {
    const response = await this.instalmentPlanClient.post<NewInstalmentPlanPreview, AxiosResponse<InstalmentPlanPreview>>(
      this.apiDomain + '/instalment-plans/preview',
      payload,
    );
    return response.data;
  }

  public static async createInstalmentPlanPreviewForExistingInstalmentPlan(
    instalmentPlanId: string,
    payload: NewInstalmentPlanPreview,
  ): Promise<InstalmentPlanPreview> {
    const response = await this.instalmentPlanClient.post<NewInstalmentPlanPreview, AxiosResponse<InstalmentPlanPreview>>(
      this.apiDomain + '/instalment-plans/' + instalmentPlanId + '/preview',
      payload,
    );
    return response.data;
  }

  public static async createInstalmentPlanInDraft(payload: NewInstalmentPlan): Promise<InstalmentPlan> {
    const response = await this.instalmentPlanClient.post<NewInstalmentPlan, AxiosResponse<InstalmentPlan>>(
      this.apiDomain + '/instalment-plans',
      payload,
    );
    return response.data;
  }

  public static async editInstalmentPlanInDraft(instalmentPlanId: string, payload: NewInstalmentPlan): Promise<InstalmentPlan> {
    const response = await this.instalmentPlanClient.put<NewInstalmentPlan, AxiosResponse<InstalmentPlan>>(
      this.apiDomain + '/instalment-plans/' + instalmentPlanId,
      payload,
    );
    return response.data;
  }

  public static async startInstalmentPlan(instalmentPlanId: string): Promise<InstalmentPlan> {
    const response = await this.instalmentPlanClient.post<InstalmentPlan>(this.apiDomain + '/instalment-plans/' + instalmentPlanId + '/_start');
    return response.data;
  }

  public static async patchInstalmentPlan(instalmentPlanId: string, payload: NewInstalmentPlan): Promise<InstalmentPlan> {
    const response = await this.instalmentPlanClient.patch<NewInstalmentPlan, AxiosResponse<InstalmentPlan>>(
      this.apiDomain + '/instalment-plans/' + instalmentPlanId,
      payload,
    );
    return response.data;
  }

  public static async deleteInstalmentPlan(instalmentPlanId: string): Promise<boolean> {
    const response = await this.instalmentPlanClient.delete(this.apiDomain + '/instalment-plans/' + instalmentPlanId);
    return response.status === 200;
  }

  public static async cancelInstalmentPlan(instalmentPlanId: string): Promise<InstalmentPlan> {
    const response = await this.instalmentPlanClient.post<InstalmentPlan>(this.apiDomain + '/instalment-plans/' + instalmentPlanId + '/_cancel');
    return response.data;
  }
}
