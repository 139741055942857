import { Products } from './Product/Product';

export type Filter = {
  statuses?: string;
  states?: string;
  startDate?: string;
  endDate?: string;
  search?: string;
  debitorId?: string;
  debtorRef?: string;
  productTypes?: string;
  productIds?: string;
  billingType?: Products.BillingType;
};

const isFilterSet = (filterValue?: string): boolean => {
  return !!filterValue && filterValue.trim() !== '';
};

export const prepareFilterParameters = (filters: Filter): { [k: string]: any } => {
  let params: { [k: string]: any } = {};
  if (isFilterSet(filters.search)) {
    params['search'] = filters.search;
  }
  if (isFilterSet(filters.states)) {
    params['states'] = filters.states;
  }
  if (isFilterSet(filters.statuses)) {
    params['statuses'] = filters.statuses;
  }
  if (isFilterSet(filters.startDate)) {
    params['startDate'] = filters.startDate;
  }
  if (isFilterSet(filters.endDate)) {
    params['endDate'] = filters.endDate;
  }
  if (isFilterSet(filters.debtorRef)) {
    params['debitorRef'] = filters.debtorRef;
  }
  if (isFilterSet(filters.debitorId)) {
    params['debitorId'] = filters.debitorId;
  }
  if (isFilterSet(filters.productTypes)) {
    params['productTypes'] = filters.productTypes;
  }
  if (isFilterSet(filters.productIds)) {
    params['productIds'] = filters.productIds;
  }
  if (isFilterSet(filters.billingType)) {
    params['billingType'] = filters.billingType;
  }
  return params;
};
