import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { Alert } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { formatCurrency } from '../../../utils/PaymentUtil';
import {
  createNewInvoice,
  finalizeInvoice,
  finalizeInvoiceCancellation,
  finalizeInvoiceCorrection,
  formValuesByInvoice,
  updateInvoice,
} from './InvoiceDialogFunctions';
import InvoiceForm from './InvoiceForm';
import { createSchemas } from './InvoiceCreationSchema';
import { Invoice, InvoiceFormValues } from '../../../types/Invoice/Invoice';
import { InvoiceListDataContext } from '../InvoiceListDataContextProvider';
import { Debtor } from '../../../types/Debtor/Debtor';
import { AxiosError } from 'axios';

interface InvoiceDialogProps {
  invoice?: Invoice;
  debtor?: Debtor;
  onClose: () => void;
}

export enum Mode {
  EDITING,
  VIEWING,
  REVIEW,
  SUBMITTING,
}

const InvoiceDialog: FC<InvoiceDialogProps> = ({ invoice, onClose, debtor }) => {
  const [usedInvoice, setUsedInvoice] = useState(invoice);
  const [mode, setMode] = useState(Mode.EDITING);

  const { t } = useTranslation('debitors');
  const alerting = useContext(AlertContext);
  const context = useContext(InvoiceListDataContext);
  const alertContext = useContext(AlertContext);

  const { t: tValidations } = useTranslation('validations');
  const schema = createSchemas(tValidations)[0];
  const initialValues = formValuesByInvoice(usedInvoice);
  initialValues.debitorId = debtor?.id;

  const isFormPrefilled = Boolean(invoice) || Boolean(debtor);

  const _handleSave = async (values: InvoiceFormValues) => {
    var successHandler = (invoice: Invoice) => {
      setUsedInvoice(invoice);
      setMode(Mode.VIEWING);
      context.reload();
    };

    var errorHandler = (error: AxiosError) => {
      alertContext.sendAlert('error', error.response?.data['message'] || error.message);
    };

    if (usedInvoice === undefined) {
      createNewInvoice(values).then(successHandler).catch(errorHandler);
    } else {
      updateInvoice(usedInvoice, values).then(successHandler).catch(errorHandler);
    }
  };

  const onSend = async () => {
    if (usedInvoice !== undefined) {
      setMode(Mode.SUBMITTING);
      let promise;
      if (usedInvoice.invoiceType === 'CANCELLATION_INVOICE') {
        promise = finalizeInvoiceCancellation(usedInvoice);
      } else if (usedInvoice.invoiceType === 'CORRECTIVE_INVOICE') {
        promise = finalizeInvoiceCorrection(usedInvoice);
      } else {
        promise = finalizeInvoice(usedInvoice);
      }
      promise
        .then(() => {
          onClose();
          alerting.sendAlert('success', t('invoice finalized'));
          context.reload();
        })
        .catch(() => {
          alerting.sendAlert('error', t('Something went wrong with finalization'));
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={_handleSave}
      validateOnMount={isFormPrefilled}
      initialTouched={isFormPrefilled ? { debitorId: true } : {}}
    >
      {({ setFieldValue, values, isValid }) => (
        <Form>
          <Dialog
            maxWidth="md"
            fullWidth
            aria-labelledby="alert-dialog-title"
            open={true}
            onClose={(event, reason) => {
              if (reason === 'backdropClick') {
                return false;
              }
              onClose();
            }}
          >
            <InvoiceDialogTitle invoice={usedInvoice} />
            <Alert severity="success">
              <Typography>{values.directDebitActivated ? t('direct debit invoice') : t('online payment invoice')}</Typography>
            </Alert>
            <DialogContent dividers>
              <InvoiceForm invoice={usedInvoice} debtor={debtor} mode={mode} setFieldValue={setFieldValue} />
            </DialogContent>
            {mode === Mode.REVIEW && (
              <Alert severity="success">
                <b>
                  {t('send_text1')} {formatCurrency(usedInvoice?.total)} {''}
                  {t('send_text2')} {''}
                  {usedInvoice?.debitor.firstName} {usedInvoice?.debitor.lastName} {t('send_text3')}
                  <br />
                  <br />
                </b>
                {t('text3')}
              </Alert>
            )}
            <DialogActions>
              <InvoiceDialogButtons
                isValid={isValid}
                invoice={usedInvoice}
                setMode={setMode}
                mode={mode}
                onSave={_handleSave}
                values={values}
                onClose={onClose}
                onSend={onSend}
              />
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

interface InvoiceDialogTitleProps {
  invoice: Invoice | undefined;
}

const InvoiceDialogTitle: FC<InvoiceDialogTitleProps> = ({ invoice }) => {
  const { t } = useTranslation('debitors');
  return invoice ? (
    <DialogTitle id="alert-dialog-title">
      {t('edit invoice')} <Chip label={invoice.invoiceNumber} />
    </DialogTitle>
  ) : (
    <DialogTitle id="alert-dialog-title">{t('create invoice')}</DialogTitle>
  );
};

interface InvoiceDialogButtonsProps {
  invoice: Invoice | undefined;
  mode: Mode;
  isValid: boolean;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  onSave: (values: InvoiceFormValues) => void;
  values: InvoiceFormValues;
  onClose: () => void;
  onSend: () => void;
}

const InvoiceDialogButtons: FC<InvoiceDialogButtonsProps> = ({ invoice, mode, isValid, setMode, onSave, values, onClose, onSend }) => {
  const { t } = useTranslation('debitors');
  return (
    <>
      {!invoice && mode === Mode.EDITING && (
        <Button color="primary" onClick={() => onClose()}>
          {t('global:cancel')}
        </Button>
      )}
      {invoice && [Mode.EDITING, Mode.VIEWING].includes(mode) && (
        <Button color="primary" onClick={() => onClose()}>
          {t('global:close')}
        </Button>
      )}
      {mode === Mode.VIEWING && (
        <Button color="primary" onClick={() => setMode(Mode.EDITING)}>
          {t('continue editing')}{' '}
        </Button>
      )}
      {mode === Mode.EDITING && (
        <Button variant="contained" disabled={!isValid} color="primary" onClick={() => onSave(values)}>
          {t('save')}{' '}
        </Button>
      )}
      {mode === Mode.VIEWING && isValid && (
        <Button variant="contained" color="primary" onClick={() => setMode(Mode.REVIEW)}>
          {t('review invoice')}
        </Button>
      )}
      {[Mode.REVIEW, Mode.SUBMITTING].includes(mode) && isValid && (
        <>
          <Button color="primary" onClick={() => setMode(Mode.VIEWING)}>
            {t('global:back')}
          </Button>
          <LoadingButton loading={mode === Mode.SUBMITTING} onClick={() => onSend()}>
            {t('send invoice')}
          </LoadingButton>
        </>
      )}
    </>
  );
};

export default InvoiceDialog;
