import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DebtorPortalLinkDialog } from './DebtorPortalLinkDialog';
import InfoIcon from '@material-ui/icons/Info';

const DebtorPortalLinkButton = () => {
  const { t } = useTranslation('payments');
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);

  return (
    <>
      <DebtorPortalLinkDialog onClose={() => setLinkDialogOpen(false)} open={linkDialogOpen} />
      <Button variant="contained" color="primary" onClick={() => setLinkDialogOpen(true)} startIcon={<InfoIcon />}>
        {t('debtor portal link button label')}
      </Button>
    </>
  );
};

export default DebtorPortalLinkButton;
